body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("https://images.pexels.com/photos/1022692/pexels-photo-1022692.jpeg?auto=compress&cs=tinysrgb&w=1600");
  background-color: #cccccc;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.white {
  color: #cccccc;
  font-family: Brushstroke;
}

.header-title {
  text-align: center;
  font-family: Copperplate, Papyrus, fantasy;
  text-shadow: 2px 2px 5px rgb(69, 72, 72);
  color: #251b19;
  font-size: 20px;
}

.scroll-container {
  position: relative;
  height: 75vh;
  overflow: auto;
}

.scroll-y {
  display: block;
}

.shaker {
  animation: rotate 0.3s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(-35deg);
  }
  50% {
    transform: rotate(35deg);
  }
  100% {
    transform: rotate(-35deg);
  }
}

.neon {
  color: white;
  text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #ff1177,
    0 0 70px #ff1177, 0 0 80px #ff1177, 0 0 100px #ff1177, 0 0 150px #ff1177;
  animation: neon 1s ease-in-out infinite alternate;
  padding-bottom: 30px;
  font-size: 40px;
}

@keyframes neon {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #16ff26,
      0 0 70px #16ff26, 0 0 80px #16ff26, 0 0 100px #16ff26, 0 0 150px #16ff26;
  }
  to {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #ff1177,
      0 0 35px #ff1177, 0 0 40px #ff1177, 0 0 50px #ff1177, 0 0 75px #ff1177;
  }
}

.no-underline {
  text-decoration: none;
}

.unknown {
  background-image: url("/public/lost.jpg");
  background-size: cover;
  background-position: center;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100vh;
}
